import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import CatalogueRoll from '../components/CatalogueRoll';

import ContentContainer from '../components/Layouts/ContentContainer';
import Section from '../components/Layouts/ContentLayout/Section';
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';

// Export so CMS Preview page can use it.
export const CategoryPageTemplate = ({ cards, details }) => {
  const backButtonStyle = {
    margin: '0 0 10px 0',
    fontSize: '1.2em',
  };

  return (
    <div>
      <Breadcrumb
        paths={[
          { text: 'Products & Services', link: '/categories' },
          { text: details.name },
        ]}
      />
      <ContentContainer>
        <div className="columns">
          <Section
            title={details.name}
            description={details.description}
          ></Section>
        </div>
        <CatalogueRoll cards={cards}></CatalogueRoll>
        {(cards && cards.length > 0) || (
          <p>There is currently no products in this category.</p>
        )}
      </ContentContainer>
    </div>
  );
};

const CategoryPage = ({ data }) => {
  const { nodes } = data.subCategories;
  const details = data.categoryDetail.frontmatter;
  const categories = nodes.map((node) => {
    const frontmatter = node.frontmatter;
    return {
      title: frontmatter.name,
      subtitle: 'Category',
      image: frontmatter.featuredimage,
      link: node.fields.slug,
      textColor: '#fff',
      bgColor: '#00b7a0',
    };
  });

  const productNodes = data.products.nodes;
  const cards = categories.concat(
    productNodes.map((node) => {
      const frontmatter = node.frontmatter;
      return {
        title: frontmatter.name,
        subtitle: 'Brand: ' + frontmatter.brand,
        image: frontmatter.featuredimage,
        link: node.fields.slug,
      };
    })
  );

  return (
    <Layout>
      <CategoryPageTemplate
        cards={cards}
        details={details}
      ></CategoryPageTemplate>
    </Layout>
  );
};

export default CategoryPage;

export const pageQuery = graphql`
  query CategoryPage($id: String!, $parent: String!) {
    categoryDetail: markdownRemark(id: { eq: $id }) {
      frontmatter {
        description
        name
        parent
      }
    }
    subCategories: allMarkdownRemark(
      filter: {
        frontmatter: { queryKey: { eq: "categories" }, parent: { eq: $parent } }
      }
    ) {
      nodes {
        frontmatter {
          featuredimage {
            childImageSharp {
              fluid(maxWidth: 400, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          description
          name
          parent
        }
        fields {
          slug
        }
      }
    }
    products: allMarkdownRemark(
      filter: {
        frontmatter: {
          queryKey: { eq: "products" }
          categories: { eq: $parent }
        }
      }
    ) {
      nodes {
        frontmatter {
          featuredimage {
            childImageSharp {
              fluid(maxWidth: 400, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          name
          brand
        }
        fields {
          slug
        }
      }
    }
  }
`;
