import React from 'react';
import { Link } from 'gatsby';

import classes from './breadcrumb.module.scss';

const Breadcrumb = ({ paths }) => {
  const _getLi = (data) => {
    return data.map((v) => {
      return (
        <li className="column is-1" key={v.link || v.text}>
          {v.link ? <Link to={v.link}>{v.text}</Link> : v.text}
        </li>
      );
    });
  };

  return (
    <div className="columns">
      <ul className={classes.breadcrumb}>{_getLi(paths)}</ul>
    </div>
  );
};

export default Breadcrumb;
